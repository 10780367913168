import { Flex } from "@rebass/grid/emotion"
import styled from "@emotion/styled"

export const HeaderWrap = styled.header`
  background: transparent;

  &.has-ribbon {
    @media (min-width: 768px) {
      margin-top: 0px;
    }
  }

  .toggler-gradient {
    background: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 4.93%
    );

    a {
      width: 100%;
      text-align: center;
      margin: auto;
      display: block;

      svg {
        display: inline;
        transform: translate(0, -1px);
      }
    }
  }

  .dropdown-shop-desktop {
    left: -6px;
  }

  &.product-page {
    @media (max-width: 767px) {
      box-shadow: none;
    }

    .container {
      background-color: rgba(255, 255, 255, 1);

      .stickyatcheader {
        height: 80px;
      }

      .stickyatcheader,
      .nonstickyheader {
        .navigation * {
          color: #07253a !important;
        }

        .navigation * .btn-green {
          color: #00857d !important;
        }

        .arrow-down *,
        .logo-wrap *,
        .right-wrap * {
          fill: #07253a !important;
        }

        .right-wrap .account-flex * {
          fill: #008078 !important;
        }

        #HamburgerOpenCloseIcon * {
          //stroke: #07253A !important;
        }
      }
    }
  }

  &.nav-open {
    .wrap {
      position: relative;

      .toggle-nav {
        margin-top: 0;
        margin-left: 0;

        span {
          transform: rotate(45deg) !important;
          top: 1.5rem;
          width: 20px;

          &:before {
            background-color: transparent;
          }

          &:after {
            top: 0;
            transform: rotate(-90deg) !important;
          }
        }
      }
    }

    &.second-level-active {
      .back-wrapper {
        display: inline-block;
      }
    }
  }

  &[data-mousedown] {
    *:focus {
      outline: none;
    }
  }

  @media (min-width: 768px) {
    height: 72px;
  }
  @media (min-width: 1024px) {
    height: 80px;
  }
`
export const HeaderContainer = styled.div`
  > .lower-wrap {
    height: 36px;
    padding: 6px 8px 10px 8px;

    &--customer-name {
      font-size: 12px;
      line-height: 16.8px;
    }
  }

  background: #464d52;
  padding: 0 !important;
  @media (min-width: 768px) {
    border-radius: 4px;
    padding: 0 0 0 2px !important;
  }
  @media (min-width: 1024px) {
    padding: 0 0 0 6px !important;
  }
`
export const ContentsContainer = styled.div`
  @media (min-width: 768px) {
    font-size: 0;
    .toggler-wrap {
      order: 1;
      width: 104px;
      padding: 0 0 0 2px;
    }

    .left-wrap {
      order: 3;
      width: calc(100% - 475px);
      align-self: center;
      padding-left: 24px;
    }

    .center-wrap {
      order: 2;
      width: 125px;
      align-self: center;
    }

    .right-wrap {
      order: 4;
      width: 226px;
      align-self: center;
    }
  }
  @media (min-width: 900px) {
    .left-wrap {
      width: calc(100% - 480px);
    }

    .right-wrap {
      padding-right: 12px;
      width: 265px;
    }
  }
  @media (min-width: 1024px) {
    .left-wrap {
      order: 2;
      width: calc(50% - 171px);
      padding-left: 0;
    }

    .center-wrap {
      order: 3;
      width: 146px;
    }

    .right-wrap {
      order: 4;
      width: calc(50% - 80px);
      padding-right: 24px;
    }
  }
`

export const BelowFoldContainer = styled.div`
  height: inherit;
  @media (min-width: 768px) {
    padding: 12px 4px 0 0;
  }
  @media (min-width: 1024px) {
    padding: 8px 4px 0 0;
  }
`

export const MobileAlt = styled.button`
  display: contents;
  text-align: center;
  height: 56px;

  p[class*="mono--"] {
    line-height: 56px !important;
    height: 56px;
    color: #fff !important;
    background: #00857d;
    font-size: 18px;
    border-radius: 4px;
    width: calc(100% - 16px);
    margin: 8px auto;
  }

  @media (min-width: 768px) {
    display: none;
  }
`

export const NavVar1 = styled(Flex)`
  background-color: #f8f8f5;
  height: 44px;
  border-bottom: 1px #c8d2d1 solid;

  button.textLink {
    font-size: 12px !important;
  }

  .takequiz {
    height: 100%;
    align-items: center;
    display: flex;
    padding: 0 16px;
    background: rgb(195, 223, 233);

    .textLink {
      color: #22202f !important;
      font-weight: 700 !important;
      border: 0 !important;
      text-transform: initial !important;

      span {
        font-family: "Dinamo Regular", Helvetica, Arial, sans-serif;
      }
    }
  }
`
