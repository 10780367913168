
import styled from "styled-components"
import theme from "../../../../../../nutrafol-ui-kit/styles/theme"
import { Box } from "@rebass/grid/emotion"

export const FooterContainer = styled.footer`
  background: ${theme.wild.color.nova.base.white};
  color: ${theme.wild.color.nova.base.black};

  &[data-mousedown] {
    *:focus {
      outline: none !important;
    }
  }

  .content-wrapper {
    p:not(:last-of-type) {
      margin-bottom: 8px;
    }

    a {
      display: block;
      width: max-content;

      p {
        width: max-content;
        margin-bottom: 0;
      }
    }
  }
`

export const MainBox = styled(Box)`
  width: 100%;
  max-width: 1442px;
  margin: auto;
  padding: 48px 24px !important;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 40px;

  @media (min-width: 768px) {
    padding: 48px 32px !important;
    grid-template-columns: repeat(8, 1fr);
  }

  @media (min-width: 1024px) {
    padding: 80px 32px !important;
    grid-template-columns: repeat(10, 1fr);
  }
`

export const LogoWrap = styled.div`
  margin-bottom: 40px;

  @media (min-width: 768px) {
    margin-bottom: 0;
    margin-right: 40px;
  }

  svg {
    width: 40px;
    height: 40px;
  }
`

export const StyledLeftSide = styled.div`
  @media (min-width: 768px) {
    grid-area: 1 / 1 / 2 / 5;
  }

  @media (min-width: 1024px) {
    grid-area: 1 / 1 / 2 / 8
  }

  .flex {
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }
`

export const StyledRightSide = styled.div`
  @media (min-width: 768px) {
    grid-area: 1 / 6 / 2 / 9;
  }

  @media (min-width: 1024px) {
    grid-area: 1 / 9 / 2 / 11;
  }
`

export const StyledLinks = styled.div`
  width: 100%;

  .section-header {
    margin-bottom: 16px;
    color: ${theme.wild.color.nova.transparency.black[60]};
  }

  .link {
    line-height: 1.3;
    margin-bottom: 16px;

    p {
      display: inline-block;
    }
  }

  .link:last-of-type {
    margin: 0;
  }
`

export const AdChoicesWrapper = styled(Box)`
  span.textelement.body--regular {
    font-size: 12px;
    line-height: 16px;
    text-decoration: underline;
  }
`