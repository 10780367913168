// contentful press entry id
export const CF_PRESS_ID = "2jY61C1iSM0HET4wCNVpeK"

// Warning: PROMO IDS in this file are lowercase, even if they are camelCase in Magento.

export const PROMO_ID = "newstart20"
export const PROMO_ID_FEB = "restart20feb"
export const PROMO_ID_25 = "restart25"
export const PROMO_ID_25_UPDATE = "restart25update"
export const PROMO_ID_10 = "restart10variant"
export const PROMO_ID_15 = "restart15variant"
export const PROMO_AUG_2024 = "augustoffer"
export const PROMO_GBB30 = "gbb30"
export const PROMO_PVOLVE25 = "aug25pvolve"
export const PROMO_PVOLVE50 = "aug50pvolve"
export const PROMO_ANCESTRY25 = "aug25ancestry"
export const PROMO_ANCESTRY50 = "aug50ancestry"
export const LABORDAY_2024_3MONTH = "ldwchrn20"
export const LABORDAY_2024_6MONTH = "ldwchrn25"
//not sure where codes came from lets keep till LD24 ends
export const LABORDAY_2024_3MONTH_VAR = "laborday20243-monthchrn"
export const LABORDAY_2024_6MONTH_VAR = "laborday20246-monthchrn"