import React, { useState, useContext, useEffect } from "react"
import styled from "@emotion/styled"
import { Flex, Box } from "reflexbox"
import { navigate } from "gatsby"

import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"
import popupHelper from "@nutrafol/popup-helper"
import theme from "@nutrafol/nutrafol-ui-kit/styles/theme"
import { queries } from "@nutrafol/nutrafol-ui-kit/styles/mediaqueries"
import { TextElement } from "@nutrafol/nutrafol-ui-kit/dist/TextElement"
import { ButtonWild } from "@nutrafol/nutrafol-ui-kit/dist/ButtonWild"

import LoadingAnimation from "../../../loading/loadinganimation"
import CartContext from "../../../../context/CartContext"
import CloseCartIcon from "../../../../assets/icons/wild/close-cart-icon.svg"
import CheckmarkIcon from "../../../../assets/icons/wild/checkmark-icon.svg"
import { castPrice } from "../../../../utils/general"

const LABELVIEW = "View Cart"
const LABELCHECKOUT = "Checkout"

const MiniCartWrap = styled.div`
  position: absolute;
  z-index: 12;
  right: 0;
  top: 0;
  padding: 12px 16px;
  width: 100%;
  height: auto;
  min-height: 128px;
  box-shadow: 0px 24px 38px 0px rgba(0, 0, 0, 0.12);
  background: ${theme.wild.color.master.base.secondary};
  ${queries.medium`
    top: 76px;
    width: 375px;
    height: auto;
    min-height: 128px;
    box-shadow: 0px 24px 38px 0px rgba(0, 0, 0, 0.12);
  `}
  ${queries.large`
    top: 83px;
  `}
`
const IconAddedBox = styled(Box)`
  border-bottom: 1px solid rgba(200, 210, 209, 1);
  padding-bottom: 15px;
  margin-bottom: 15px;
  svg {
    width: 12px;
    height: 9px;
    transform: translateY(5px);
    path {
      fill: #008078;
    }
    ${queries.large`
      transform: translateY(8px);
    `}
  }
  svg, p {
    display: inline-block;
    vertical-align: top;
  }
`
const MiniCartContent = styled(Flex)`
  width: 100%;
  padding: 24px 16px;
  background: ${theme.wild.color.master.base.default};
  max-height: calc(100vh - 145px);
  ${queries.medium`
  max-height: calc(100vh - 250px);
  `}
  overflow: auto;
`
const FreeShippingContent = styled(Flex)`
  width: 100%;
  padding: 4px 16px;
  background: ${theme.wild.color.master.base.default};
`
const ButtonContent = styled(Flex)`
  a, button {
    width: 100%;
    display: block;
    text-align: center;
  }
`

let scrollY = 0

const MiniCart = () => {

  const [isOpen, setIsOpen] = useState(false)
  const [prices, setPrices] = useState([])
  const [showCents, setShowCents] = useState(false)
  const cartState = useContext(CartContext)
  
  /*
   * too late to trackActiveElement here, moved tracking activeElement to Context
   *  useEffect(() => {
   *    popupHelper.trackActiveElement()
   *  }, [])
   */
  
  useEffect(() => {
    if (prices) {
      const castedPrices = prices.map((price) => castPrice(price))
      const shouldShowCents = castedPrices.some((item) => {
        return item?.split(".")?.[1] && item?.split(".")?.[1] !== "00"
      })
      setShowCents(shouldShowCents)
    }
  }, [prices, setShowCents])

  useEffect(() => {
    const rawPrices = []
    if ((typeof cartState.cartData === 'object') && cartState.cartData[1]) {
      Object.keys(cartState.cartData[1]).forEach((product) => {
        const item = cartState.cartData[1][product]
        rawPrices.push(item.quote.row_total)
      })
      rawPrices.push(cartState.cartData[0].grand_total)
      setPrices(rawPrices)
    }
  }, [cartState, setPrices])

  useEffect(() => {
    if (cartState.isLoadingAjaxCart) {
      setIsOpen(true)
      if(popupHelper.isMobile()){
        //debugger
        scrollY = window.scrollY || scrollY
        // console.log('opening', scrollY)

        //html tag
        document.documentElement.style.height = '100vh'
        //body tag
        document.body.style.left = `0`
        document.body.style.right = `0`
        document.body.style.position = 'fixed'
        document.body.style.top = `-${scrollY}px`
        document.body.style.height = `100vh`
      }
    } else {
      popupHelper.onOpen({
        wrapper: "mini-cart-wrap",
        closeEl: "mini-cart-close"
      })
    }
  }, [cartState, setIsOpen])

  const handleCheckout = (text=LABELVIEW, destination="/checkout/cart/", outside)=> {

    segmentEvent('Marketing CTA Clicked', {
      destination,
      text,
      module_location: "mini_cart",
      type: "button"
    })

    setTimeout(()=>{
      if(outside){
        window.location = process.env.GATSBY_MAGENTO_URL + destination
      } else {
        navigate(destination)
      }
    }, 500)

  }
  
  return (
    <CartContext.Consumer>
      {cartState => (
        <MiniCartWrap className={`rounded-lg ${isOpen ? '' : 'hidden'}`} id={`mini-cart-wrap`}>
          <Flex flexWrap="wrap">
            {cartState.isLoadingAjaxCart ? (
              <Box width={1}>
                <LoadingAnimation contained={true} isMiniCart={true} />
              </Box>
            ) : (
              <>
                {!!cartState.cartData  && cartState.cartData !== "Quote does not exist."  ? (
                  <>
                    <Box width={1} className="text-right" pb={"8px"}>
                      <button onClick={() => {
                        setIsOpen(false)
                        //html tag
                        document.documentElement.style.removeProperty('height')
                        //body tag
                        document.body.style.removeProperty('position')
                        document.body.style.removeProperty('top')
                        document.body.style.removeProperty('left')
                        document.body.style.removeProperty('right')
                        document.body.style.removeProperty('height')
                        //console.log('closing', scrollY)
                        window.scrollTo(0, scrollY)
                        if(cartState.activeElement && cartState.activeElement.focus) {
                          try {
                            cartState.activeElement.focus()
                          } catch (e) {
                            console.error(`activeElement  focus error`)
                          }
                        }
                      }} className="p-1" id={`mini-cart-close`}>
                        <CloseCartIcon />
                      </button>
                    </Box>
  
                    { cartState.hasError ?
                      <MiniCartContent flexWrap="wrap" className="rounded-lg">
                        <Box width={1}>
                          <TextElement text="There was an error, please wait and try again later." element="h3" className="body--xlarge-b text-master-functional-error" />
                        </Box>
                      </MiniCartContent> :
                      
                      (typeof cartState.cartData[1] === "object" && Object.keys(cartState.cartData[1]).length > 0 ?
                        <>
                          <MiniCartContent flexWrap="wrap" className="rounded-lg">
                            <IconAddedBox width={1}>
                              <CheckmarkIcon />
                              <TextElement text={`Item${cartState.multipleSkusAdded ? 's' : ''} added to your cart`} element="p" className="body--large-b text-master-primary-default ml-2" />
                            </IconAddedBox>
                            {Object.keys(cartState.cartData[1]).map((product, i) => {
                              const item = cartState.cartData[1][product]
                              return (
                                <Box width={1} pt={i === 0 ? "0px" : "8px"} pb="8px" key={`item-${i}`}>
                                  <Flex flexWrap="wrap" alignItems="top">
                                    <Box width={7/10} className="text-left">
                                      <TextElement text={item.name.replace(/\\/g,"")} element="p" className="inline-block align-top body--large text-master-base-black" />
                                      {item.quote.qty > 1 &&
                                      <TextElement text={`x ${item.quote.qty}`} element="p" className="inline-block align-top body--large text-master-base-black pl-1" />
                                      }
                                    </Box>
                                    <Box width={3/10} className="text-right">
                                      <TextElement text={`$${Number(item.quote.row_total).toFixed(showCents ? 2 : 0)}`} className="inline-block align-top body--large text-master-base-black" />
                                    </Box>
                                  </Flex>
                                </Box>
                              )}
                            )}
                            {cartState.cartData[0].discount && cartState.cartData[0].coupon_code ? (
                              <Box width={1} pt="8px" pb="8px">
                                <Flex flexWrap="wrap">
                                  <Box width={7/10} className="text-left">
                                    <TextElement text={`Discount (${cartState.cartData[0].coupon_code})`} element="p" className="body--large text-master-base-black" />
                                  </Box>
                                  <Box width={3/10} className="text-right">
                                    <TextElement text={`- $${Number(cartState.cartData[0].discount).toFixed(showCents ? 2 : 0)}`} className="body--large text-master-base-black" />
                                  </Box>
                                </Flex>
                              </Box>
                            ) : null}
                            <Box width={1} pt="8px">
                              <Flex flexWrap="wrap">
                                <Box width={1/2} className="text-left">
                                  <TextElement text="Sub-Total" element="p" className="body--xlarge-b text-master-primary-default" />
                                </Box>
                                <Box width={1/2} className="text-right">
                                  <TextElement text={`$${Number(cartState.cartData[0].grand_total).toFixed(showCents ? 2 : 0)}`} className="body--xlarge-b text-master-primary-default" />
                                </Box>
                              </Flex>
                            </Box>
                          </MiniCartContent>
                          <FreeShippingContent flexWrap="wrap" className="rounded-lg" my={"8px"}>
                            <Box width={1}>
                              <TextElement text="Free Shipping" element="p" className="body--regular-b text-master-primary-default text-center" />
                            </Box>
                          </FreeShippingContent>
                          <ButtonContent flexWrap="wrap" width="100%">
                            <Box width={1/2} pr={1}>
                              <ButtonWild
                                secondary
                                onClick={()=>{
                                  handleCheckout()
                                }}
                                label={LABELVIEW}
                              />
                            </Box>
                            <Box width={1/2} pl={1}>
                              <ButtonWild
                                primary
                                arrowRight
                                label={LABELCHECKOUT}
                                onClick={() => {
                                  handleCheckout(LABELCHECKOUT, `checkout/index/`, true)
                                }}
                              />
                            </Box>
                          </ButtonContent>
                        </> : null)
                    }

                  </>
                ) : (
                  <Box width={1}>
                    <LoadingAnimation contained={true} isMiniCart={true} />
                  </Box>
                )}
              </>
            )}
          </Flex>
        </MiniCartWrap>
      )}
    </CartContext.Consumer>
  )
}

export default MiniCart