import styled from "@emotion/styled"
import theme from "../../../../../nutrafol-ui-kit/styles/theme"
import { Flex } from "@rebass/grid/emotion"

export const RibbonLink = styled.a`
  flex: 1;
  cursor: pointer;
  width: 100%;
  color: ${theme.wild.color.nova.base.black};
  font-family: ${theme.wild.font.dinamo.regular};
  font-size: 11px;
  line-height: 16px;
  padding: 8px 0;
  @media (min-width: 420px) {
    .special-br-1 {
      display: none;
    }
  }
  @media (min-width: 374px) {
    font-size: 12px;
  }
  @media (min-width: 768px) {
    padding: 8px 0;
    line-height: 130%;
  }
  @media (min-width: 1024px) {
    font-size: 14px;
    line-height: 20px;
    padding: 8px 0;
  }

  &.ribbon-ab-test-a {
    //background: green;
    padding: 16px 0 20px;
    @media (min-width: 1024px) {
      padding: 18px 0 22px;
    }
  }

  .mobile-break {
    @media (min-width: 451px) {
      display: none;
    }
  }

  .desktop-only {
    display: none;
    @media (min-width: 768px) {
      display: inline-block;
    }
  }

  &.memorial {
    @media (min-width: 768px) {
      font-size: 14px;
    }
  }
`

export const RibbonContainer = styled(Flex)`
  transition: none;
  color: ${theme.wild.color.nova.base.black};
  position: absolute;
  height: 40px;
  margin-top: 4px;
  border-top: 1px solid #c8d2d1;
  top: 92px;
  left: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  box-shadow:
    0px 11px 15px 0px rgba(0, 87, 82, 0.08),
    0px 9px 46px 0px rgba(0, 87, 82, 0.06),
    0px 24px 38px 0px rgba(0, 87, 82, 0.06);
  border-top: none !important;
  border-radius: 8px;

  @media (max-width: 767px) {
    width: 98%;
    left: 0;
    right: 0;
    margin: auto;
    top: 100px;
  }

  .ribbon-special-br {
    display: block;
    @media (min-width: 400px) {
      display: none;
    }
  }

  @media (min-width: 768px) {
    position: relative;
    border-top: none;
    margin-top: 0;
    top: 0;
    padding: 0 12px;
    &.ribbonsub {
      margin-top: 4px;
    }
  }

  .ribbon-ab-test-a {
    display: none;

    .grilli {
      font-size: 14px;
      @media (min-width: 1024px) {
        font-size: 24px;
      }
    }

    .body--xlarge {
      @media (min-width: 1024px) {
        font-size: 24px !important;
        line-height: 33.6px !important;
      }
      font-weight: 400;

      b {
        font-weight: 700;
      }
    }

    .link {
      color: #005752;
      font-size: 14px;
      text-decoration: underline;
      margin-top: 4px;
      text-underline-offset: 5px;
      @media (min-width: 1024px) {
        font-size: 16px;
        margin-top: 8px;
      }
    }
  }
`

export const RibbonContainerSimple = styled(Flex)`
  transition: none;
  background: ${theme.wild.color.nova.base.solid[20]};
  color: ${theme.wild.color.nova.base.black};
  position: absolute;
  height: 40px;
  margin-top: 4px;
  border-top: 1px solid #c8d2d1;
  top: 92px;
  left: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  box-shadow:
    0px 11px 15px 0px rgba(0, 87, 82, 0.08),
    0px 9px 46px 0px rgba(0, 87, 82, 0.06),
    0px 24px 38px 0px rgba(0, 87, 82, 0.06);
  border-top: none !important;
  border-radius: 8px;

  @media (max-width: 767px) {
    width: 98%;
    left: 0;
    right: 0;
    margin: auto;
    top: 64px;
  }

  .ribbon-special-br {
    display: block;
    @media (min-width: 400px) {
      display: none;
    }
  }

  @media (min-width: 768px) {
    position: relative;
    border-top: none;
    margin-top: 0;
    top: 0;
    padding: 0 12px;
    &.ribbonsub {
      margin-top: 4px;
    }
  }

  .ribbon-ab-test-a {
    display: none;

    .grilli {
      font-size: 14px;
      @media (min-width: 1024px) {
        font-size: 24px;
      }
    }

    .body--xlarge {
      @media (min-width: 1024px) {
        font-size: 24px !important;
        line-height: 33.6px !important;
      }
      font-weight: 400;

      b {
        font-weight: 700;
      }
    }

    .link {
      color: #005752;
      font-size: 14px;
      text-decoration: underline;
      margin-top: 4px;
      text-underline-offset: 5px;
      @media (min-width: 1024px) {
        font-size: 16px;
        margin-top: 8px;
      }
    }
  }
`

export const CloseIconWrap = styled.button`
  display: none;
  @media (min-width: 768px) {
    display: block;
    padding: 7px;
  }
`
