import React, { useEffect, useState } from "react"
import { Flex } from "@rebass/grid/emotion"
import styled from "@emotion/styled"
import theme from "@nutrafol/nutrafol-ui-kit/styles/theme"
import { TextElementMen } from "@nutrafol/nutrafol-ui-kit/dist/TextElementMen"
import { ButtonWildMen } from "@nutrafol/nutrafol-ui-kit/dist/ButtonWildMen"
import DownNav from "../../../../assets/icons/wild/wild-caret.svg"
import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"

//Product Dropdown Viewed, Product Selected, Product Added
const AltContentsContainer = styled(Flex)`
  display: none;
  @media (min-width: 768px) {
    display: flex;
  }
`

const ButtonsWrap = styled(Flex)`
  margin-left: auto;

  button.c-btn {
    height: 56px;
  }
`
const ButtonsHolder = styled.div`
  position: relative;
  width: 362px;
  height: 56px;
  background: ${theme.color.beige};
  border-radius: 4px;
  margin: 0 8px 0 0;
  padding: 0;
  @media (min-width: 1024px) {
    width: 432px;
  }
`

const ButtonsFloat = styled.div`
  position: absolute;
  cursor: pointer;
  width: 362px;
  @media (min-width: 1024px) {
    width: 432px;
  }
  height: auto;
  top: 0;
  padding: 8px 0 0 0;
  background: #FFFFFF;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05), 0 24px 38px rgba(0, 0, 0, 0.03), 0 9px 46px rgba(0, 0, 0, 0.03), 0 11px 15px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
`

const OptionWrap = styled.button`
  width: 362px;
  @media (min-width: 1024px) {
    width: 432px;
  }
  height: 56px;
  line-height: 56px;
  background: ${theme.color.white};
  border-radius: 4px;
  border: 0px #c8c2d1 solid;

  span, svg {
    display: inline-block;
  }
  span {
    line-height: 28px !important;
    //border: 1px red dotted;
  }
   .c-price {
     width: 38px;
     text-align: left;
   }

  .c-title {
    width: 175px;
    padding: 0 0 0 8px;
    @media (min-width: 1024px) {
      width: 205px;
      padding: 0 0 0 16px;
    }
    text-align: left;

  }

  .c-savings {
    padding: 0;
    text-align: center;
    width: 18%;
    @media (min-width: 1024px) {
      width: 24%;
    }
    height: 22px;
    line-height: 22px !important;
    transform: translate(0, -4px);
    max-width: 85px;
    font-size: 10px !important;
    background: ${theme.wild.color.men.secondary.alt};
    &.no-savings {
      visibility: hidden;
    }
  }


  .body--small.c-strike {
    color: ${theme.wild.color.men.base.alt.alt};
    text-decoration: line-through;
    line-height: 18px;
    font-size: 12px;
    width: 32px;
    text-align: left;
  }
  
  .plus-icon {
    width: 20px;
    padding: 0;
    transform: translate(6px) rotate(180deg);
    @media (min-width: 1024px) {
      width: 45px;
    }
  }


  &.normal {
    border: none;
    background: ${theme.wild.color.men.base.secondary};
  }


  &.float {
    height: 80px;
    line-height: 80px;
    padding: 0;
    //width: 347px;
    @media (min-width: 1024px) {
      //width: 416px;
    }
    border: none;
    border-radius: 0;
    .plus-icon {
      visibility: hidden;
    }
    &.option-1, &.option-2 {
      &::before {
        border-top: 1px solid ${theme.wild.color.men.base.alt.secondary};
        content: '';
        display: block;
        //transform: translate(0)
        margin-left: 3%;
        width: 94%;
      }
    }
    &.option-3 {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      &::before {
       border-top: 1px solid ${theme.wild.color.men.base.alt.secondary};
        content: '';
        display: block;
        //transform: translate(0, 0px);
        margin-left: 3%;
        width: 94%;
      }
    }
    &:hover, &:focus, &:focus-visible {
      background: ${theme.wild.color.men.secondary.alt};
      &.option-0 {
        &::after {
          border-bottom-color: transparent;
        }
        + button {
          &::before {
            border-top-color: transparent;
          }
        }
      }
      &.option-1, &.option-2 {
        &::before {
          border-top-color: transparent;
        }
        + button {
          &::before {
            border-top-color: transparent;
          }
        }
      }

      &.option-3::before {
        border-top-color: transparent;
      }
      &.has-savings {
        .c-savings {
          background: #fff;
        }
      }
    }
  }
`

const Chooser = styled.div`
  //border: 1px red solid;
  width: 347px;
  @media (min-width: 1024px) {
    width: 416px;
  }
  height: 48px;
  
  padding: 12px 0 0 0;

  span, svg {
    display: inline-block;
  }
  .c-choose {
    width: 300px;
    padding: 0 0 0 16px;
    @media (min-width: 1024px) {
      width: 370px;
      padding: 0 0 0 20px;
    }
    text-align: left;

  }
  .plus-icon {
    width: 45px;
    padding: 0;
    transform: translate(14px, -1px);
    @media (min-width: 1024px) {
      transform: translate(16px, -1px);
    }
  }
`

const atcText = 'Select'

const HeaderAtc = (props) => {

  const {atcData, activeOption, setActiveOption}  = props.state
  const [isFloat, setIsFloat] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  useEffect(() => {
    if (typeof document !== "undefined") {
      const handleClick = () => {
        if(isFloat) {
          setIsFloat(false)
        }
      }
      const handleKey = function(evt) {
        evt = evt || window.event
        var isEscape = evt && (evt.key === "Escape" || evt.key === "Esc" || evt.keyCode === 27)
        if (isEscape) {
          if(isFloat) {
            setIsFloat(false)

            const header = document.querySelector("header")
            const main = document.querySelector("main")
            const footer = document.querySelector("footer")

            header && header.setAttribute("data-mousedown", "1")
            main && main.setAttribute("data-mousedown", "1")
            footer && footer.setAttribute("data-mousedown", "1")
          }
        }
      }
      document.addEventListener("click", handleClick)
      document.addEventListener("keydown", handleKey)
      return () => {
        document.removeEventListener("click", handleClick)
        document.removeEventListener("keydown", handleKey)
      }
    }
  }, [isFloat])

  if(atcData===null){
    return null
  }

  const options = atcData.options

  const atcClick = () => {
    if(isSubmitted){
      return
    }
    if(!isFloat){
      const option = options[activeOption]
      setIsSubmitted(true)
      segmentEvent("Product Added", {
        "text": atcText,
        "name": option.name,
        "sku":  option.sku,
        "price": option.price,
        "variant": option.ui_data?.title,
        "product_gender": option.product_gender,
        "billing_interval": option.billing_interval,
        "core_category": option.product_core_category,
        "product_category":  option.product_category,
        "purchase_type": option.purchase_type,
        "shipping_interval":  option.shipping_interval,
        "quantity": 1,
        "quiz_reco": false,
        "source": "mens_pdp",
        "type": "button_stickynav"
      })
      window.location.href = process.env.GATSBY_MAGENTO_URL + '/dpa/add/tocart/?skus=' + options[activeOption].sku + `&purchase_source=` + atcData.purchase_source

    }
  }

  const onSelect = (idx)=>{
    const option = options[idx]
    segmentEvent("Product Selected", {
      "variant": option?.ui_data?.title,
      "core_category": option.product_core_category,
      "name": option.name,
      "sku":  option.sku,
      "price": option.price,
      "product_gender": option.product_gender,
      "type": "dropdown_stickynav"
    })
  }

  const onOpen = ()=> {
    const option = options[activeOption]
    segmentEvent("Product Dropdown Viewed", {
      "image_url": atcData.mainimg,
      "name": option.name,
      "product_gender": option.product_gender,
      "type": "dropdown_stickynav"
    })
  }

  const renderOption = (idx, type = 'normal')=>{
    const option = options && options[idx] ? options[idx] : null
    if (!option){
      return null
    }
    option.ui_data = option.ui_data || {}
    const price = `$`+option.price
    const priceorig =   option.old_price !== option.price ? `$` + option.old_price : ''
    const scls = option?.ui_data?.savings ? 'has-savings' : 'no-savings'

    return <OptionWrap key={`key-${option?.ui_data?.id}`} id={`option-${option?.ui_data?.id}-float`} className={`option-${idx} ${type} ${scls} header-atc-option-${idx}`}  onClick={(e)=>{
      if(type === 'float'){
        setActiveOption(idx)
        onSelect(idx)
      } else {
        e.nativeEvent.stopImmediatePropagation()
        setIsFloat(true)
        onOpen()
      }
    }}>
      <TextElementMen element="span" text={price} className={`title--small c-price text-men-secondary-secondary md:ml-3`} />
      <TextElementMen element="span" aria-label={`was ${priceorig} dollars, now discounted`} text={priceorig} className={`body--small c-strike text-men-secondary-secondary ml-1`} />
      <TextElementMen element="span" text={option?.ui_data?.title} className="title--small c-title text-men-secondary-secondary" />
      <TextElementMen element="span" text={option?.ui_data?.savings} className={`${scls} mono--small c-savings text-men-secondary-secondary uppercase`}/>
      <DownNav className="plus-icon"/>
    </OptionWrap>
  }

  const mapper = options && options.map ? options.map((el,idx) => renderOption(idx, 'float')) : null

  return (
    <>
      <AltContentsContainer>
        <ButtonsWrap>
          <ButtonsHolder  >
            {renderOption(activeOption)}
            {isFloat &&
            <ButtonsFloat>
              <Chooser>
                <TextElementMen element="span" text={`Choose an Option`} className="body--regular c-choose text-master-base-alt-alt" />
                <DownNav className="plus-icon"/>
              </Chooser>
              {mapper}
            </ButtonsFloat>
            }
          </ButtonsHolder>
          <ButtonWildMen
            widarr={['120px','120px','120px']}
            className={`c-btn ${isFloat || isSubmitted ? 'disabled' : ''}`}
            primary
            label={atcText}
            onClick={atcClick}
          />
        </ButtonsWrap>
      </AltContentsContainer>

    </>
  )
}

export default HeaderAtc
