import React from "react"
import PropTypes from "prop-types"
import { Linked } from "./Linked"
import { TextElementNova } from "./TextElementNova"
import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"

import ArrowIconPrimary from "../../src/assets/icons/nova/arrow-right-button.svg"
import ArrowIconTextlink from "../../src/assets/icons/nova/arrow-textlink.svg"
import LoadingIconPrimary from "../../src/assets/icons/nova/spinner-primary.svg"
import LoadingIconSecondary from "../../src/assets/icons/nova/spinner-secondary.svg"
import LoadingIconTextlink from "../../src/assets/icons/nova/spinner-textlink.svg"

import {
  WildButtonNova,
  ArrowWrap,
  LoadingWrap,
  ChildrenWrapper,
} from "./ButtonNova.styled"

const LoadingIconMap = {
  Primary: <LoadingIconPrimary />,
  Textlink: <LoadingIconTextlink />,
}

const ArrowIconMap = {
  Textlink: <ArrowIconTextlink />,
}

/**
 * Primary UI component for user interaction - Nova edition
 * NT2-19792 Dev | Button
 * https://www.figma.com/design/2N4gMTqYSm9oGSouVxbvCW/Nutrafol---Website-2024?node-id=554-29248&t=OSU479f3QIEQGJ3w-1
 */
export const ButtonNova = ({
  level,
  ctaLink,
  icon,
  className,
  label,
  size,
  width,
  srlabel,
  loading,
  disabled,
  trackObject,
  onClick,
  copyClassName,
  children,
  ...props
}) => {
  let trackFn = () => {}
  let isLinked = !!ctaLink && ctaLink?.indexOf("#") === -1
  const LoadingIcon = LoadingIconMap[level] || <LoadingIconSecondary />
  const ArrowIcon = ArrowIconMap[level] || <ArrowIconPrimary />
  const disabledCls = disabled ? "disabled" : ""

  if (trackObject) {
    const obj = { type: "button", ...trackObject }
    obj.text = trackObject.text || label.replace(/(<([^>]+)>)/gi, "")
    delete obj.name
    trackFn = () => {
      segmentEvent(trackObject.name, obj)
    }
  }

  const onClickNew = function () {
    try {
      trackFn()
    } catch (e) {
      //  trackFn() error
    }
    if (typeof onClick === "function") {
      onClick.apply(this, arguments)
    }
  }

  const linkProps = isLinked ? { as: Linked, href: ctaLink } : {}

  //if no left or right icon passed, replace label on loading event
  const LabelOrLoader = () => {
    return loading && !["Right", "Left"].includes(icon) ? (
      <LoadingWrap className={`Only ${size} ${level}`}>
        {LoadingIcon}
      </LoadingWrap>
    ) : (
      <TextElementNova
        text={label}
        element="span"
        className={`${copyClassName} CTA-20-Dia-Normal label`}
      />
    )
  }

  return (
    <WildButtonNova
      style={{ width }}
      title={srlabel || label}
      aria-label={srlabel || label}
      type={ctaLink ? undefined : "button"}
      className={[
        className ? className.replace(/undefined/g, "") : "",
        level,
        size,
        icon || "Noicon",
        loading ? "loading" : "",
        disabledCls,
      ].join(" ")}
      onClick={onClickNew}
      {...props}
      {...linkProps}
    >
      {["Only", "Left"].includes(icon) ? (
        loading ? (
          <LoadingWrap className={`left ${level}`}>{LoadingIcon}</LoadingWrap>
        ) : (
          <ArrowWrap className={`left ${level} ${disabledCls}`}>
            {ArrowIcon}
          </ArrowWrap>
        )
      ) : null}

      {icon === "Only" ? null : LabelOrLoader()}
      {srlabel ? <span className="aria-invisible">{srlabel}</span> : null}
      {!label && children && <ChildrenWrapper>{children}</ChildrenWrapper>}
      {icon === "Right" ? (
        loading ? (
          <LoadingWrap className={`right ${level}`}>{LoadingIcon}</LoadingWrap>
        ) : (
          <ArrowWrap className={`right ${level} ${disabledCls}`}>
            {ArrowIcon}
          </ArrowWrap>
        )
      ) : null}
    </WildButtonNova>
  )
}

ButtonNova.propTypes = {
  /**
   * Is this the Primary, Secondary, Tertiary, Textlink
   */
  level: PropTypes.oneOf(["Primary", "Secondary", "Tertiary", "Textlink"]),
  /**
   * if it has a left arrow
   */
  icon: PropTypes.oneOf(["Left", "Right", "Only"]),
  /**
   * size
   */
  size: PropTypes.oneOf(["Default", "Small"]),
  /**
   * is it a link
   */
  ctaLink: PropTypes.string,
  /**
   * width eg 100%
   */
  width: PropTypes.string,
  /**
   *  className
   */
  className: PropTypes.string,
  /**
   * Button contents
   */
  label: PropTypes.string,
  /**
   * Button screen reader copy
   */
  srlabel: PropTypes.string,
  /**
   * if it is loading
   */
  loading: PropTypes.bool,
  /**
   * if it is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Segments trackObject object e.g. {destination: '/quiz', name: "Marketing CTA Clicked"}.
   */
  trackObject: PropTypes.object,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
  /**
   * Optional span copy classes
   */
  copyClassName: PropTypes.string,
}

ButtonNova.defaultProps = {
  level: "Primary",
  size: "Default",
  width: "auto",
  disabled: false,
  loading: false,
  ctaLink: "",
  copyClassName: "",
}
