const mediaqueries = {
  extrasmall: "480px",
  small: "768px",
  medium: "1024px",
  large: "1280px"
}

const theme = {
  mediaqueries,
  breakpoints: [
    mediaqueries.extrasmall,
    mediaqueries.small,
    mediaqueries.medium,
    mediaqueries.large
  ],
  /*
   *  WARNING: this array applies to headless, quiz and all project using nutrafol-ui-kit
   *  extending default spacing for reflexbox props
   *  see https://rebassjs.org/theming/
   *  see https://rebassjs.org/props/#margin-and-padding
   *  Numbers reference steps on the spacing scale
   *  e.g. 12px
   * <Text m={2} />
   *  Numbers greater than the length of `theme.space.length` are converted to pixels
   *  eg 24px
   * <Text m={24} />
   */
  space: [
    0, 5, 12, 15, 25, 50, 75, 100
  ],
  color: {
    black: "#2d2d2d",
    darkGray: "#454545",
    lighterererGray: "#f3f3f3",
    lightGray: "#737273",
    lighterGray: "#e4e4e4",
    lightererGray: "#d8d8d8",
    evenLighterGray: "#bdbbbb",
    lightestGrayEver: "#f4f4f4",
    bgBeige: "rgba(233, 225, 211, 0.3)",
    green: "#1f6360",
    darkGreen: "#14403e",
    lightGreen: "#dce6dd",
    beige: "#f8f8f5",
    lightBeige: "#fbfbfb",
    white: "#ffffff",
    maroon: "#742110",
    peach: "#C97B5E",
    yellow: "#FFE667",
    purple: "#8F6D6E",
    hormones: "#742111",
    metabolism: "#b7842b",
    stress: "#c97a5f",
    nutrition: "#14403e",
    environment: "#8f6d6e"
  },
  spacing: {
    small: "15px",
    medium: "25px",
    large: "50px",
    xlarge: "75px",
    xxlarge: "100px",
  },
  font: {
    regular: "Dinamo Regular, Helvetica, Arial, sans-serif",
    medium: "Dinamo Medium, Helvetica, Arial, sans-serif",
    light: "Dinamo Regular, Helvetica, Arial, sans-serif",
    book: "Dinamo Regular, Helvetica, Arial, sans-serif",
    mono: "Dinamo Semi Mono, Helvetica, Arial, sans-serif",
    bold: "Dinamo Bold, Helvetica, Arial, sans-serif",
    demi: "Dinamo Bold, Helvetica, Arial, sans-serif"
  },
  header: {
    height: "65px",
    medium: {
      height: "88px",
    }
  },
  weight: {
    light: 300,
    book: 300,
    regular: 400,
    heavy: 500,
    demi: 500,
    mono: 500,
    extraHeavy: 600
  },  
  wild: {
    space: [
      0, 5, 12, 15, 25, 50, 75, 100
    ],
    //color section below should be JSON - no comments, quoted keys for use in tailwind
    color: {
      "nova": {
        "base": {
          "white": "#FFFFFF",
          "solid": {
            "20": "#ECF1F5",
            "40": "#DBE0E4"
          },
          "black": "#041C1B"
        },
        "brand": {
          "primary": {
            "80": "#007A73",
            "100": "#065954"
          },
          "accent": {
            "80": "#FFC942",
            "100": "#F6B615"
          }
        },
        "functional": {
          "success": "#12AF51",
          "error": "#E22900"
        },
        "transparency": {
          "black": {
            "10": "rgba(4, 28, 27, 0.10)",
            "20": "rgba(4, 28, 27, 0.20)",
            "40": "rgba(4, 28, 27, 0.40)",
            "60": "rgba(4, 28, 27, 0.60)"
          },
          "white": {
            "10": "rgba(255, 255, 255, 0.10)",
            "20": "rgba(255, 255, 255, 0.20)",
            "40": "rgba(255, 255, 255, 0.40)",
            "60": "rgba(255, 255, 255, 0.60)"
          }
        }
      },
      "master": {
        "base": {
          "default": "#FFFFFF",
          "secondary": "#F8F8F5",
          "black": "#22202F",
          "alt": {
            "default": "#90A9A6",
            "secondary": "#C8D2D1",
            "alt": "#677574",
            "disabled": "#435652"
          }
        },
        "primary": {
          "default": "#008078",
          "secondary": "#005752",
          "alt": "#e8faf7"
        },
        "secondary": {
          "default": "#07253A",
          "secondary": "#0F3956",
          "alt": "#dce9ea"
        },
        "functional": {
          "error": "#CC2500",
          "success": "#24BB60",
          "disabled": "#9BADAB"
        }
      },
      "men": {
        "base": {
          "default": "#FFFFFF",
          "secondary": "#F8F8F5",
          "black": "#22202F",
          "alt": {
            "default": "#90A9A6",
            "secondary": "#C8D2D1",
            "alt": "#677574"
          }
        },
        "primary": {
          "default": "#008078",
          "secondary": "#005752"
        },
        "secondary": {
          "default": "#0F3956",
          "secondary": "#07253A",
          "alt": "#dce9ea"
        },
        "functional": {
          "error": "#FF3306",
          "success": "#24BB60",
          "disabled": "#9BADAB"
        }
      }
    },
    font: {
      gotham: {
        black: "Gotham Black Condensed, Open Sans Condensed, Helvetica, Arial, sans-serif",
      },
      dinamo: {
        regular: "Dinamo Regular, Helvetica, Arial, sans-serif",
        medium: "Dinamo Medium, Helvetica, Arial, sans-serif",
        mono: "Dinamo Semi Mono, Andale Mono,  Courier, monospace, sans-serif",
        bold: "Dinamo Bold, Helvetica, Arial, sans-serif",
      },
      grilli: {
        book: "Grilli Book, Garamond, Georgia, serif",
        italic: "Grilli Book Italic, Garamond, Georgia, serif",
      },
    },
    spacing: {
      small: "15px",
      medium: "25px",
      large: "50px",
      xlarge: "75px",
      xxlarge: "100px",
    },
  }
}

export default theme
